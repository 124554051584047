import { Suspense, useContext } from 'react'
import type { FC, ReactElement } from 'react'
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'
import { LayoutContext } from './LayoutContext'
import { Box } from '@mui/material'

interface LayoutProps {
	children?: ReactElement
}

const Layout: FC<LayoutProps> = ({ children }: LayoutProps = {}) => {
	const { useLayoutState } = useContext(LayoutContext)
	const state: any = useLayoutState()

	return (
		<Stack sx={{ flex: 1, width: '100%' }}>
			<Box
				component="main"
				sx={{
					display: 'flex',
					flex: 1,
					width: '100%',
					flexDirection: 'column'
				}}
			>
				{state.loading && <LinearProgress />}
				<Suspense fallback={<LinearProgress />}>{children}</Suspense>
			</Box>
		</Stack>
	)
}

export default Layout
