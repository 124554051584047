import React, { createContext, ReactNode, useReducer } from 'react'
import { generateToken } from '@basementscripts/token-generator'
import reducer, { Action } from './reducer'
import { initialState } from './state'
import { createAction } from '../utils/createAction'

// const API = config.useTestAuth ? testServices : axios

export interface AuthorizeRequest {
	email: string
	password: string
}

export const AuthContext = createContext({
	useAuthState: () => ({}),
	authenticate: (email: string, cb?: any) => {},
	logout: () => {},
	reset: () => {},
	clearError: () => {}
})

export interface AuthProviderProps {
	children: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
	const [state, dispatch]: any = useReducer<any>(reducer, initialState)

	const controller = (type: Action, payload?: any) => {
		dispatch(createAction(type, payload))
	}

	const handleError = (error: any) => {
		// const message = parseError(error)
		// controller(Action.AUTH_ERROR, message)
		// Alert.throwError(message)
	}

	const authenticate = (email: string, cb: any = () => {}) => {
		;(() => {
			if (!email) {
				handleError({ message: 'Invalid Email' })
				return
			}
			controller(Action.SET_LOADING, true)
			try {
				console.log('authenticate', email)
				// await API.post('/api/auth/authenticate', { email })
				const user = {
					user: {
						name: email,
						email
					},
					auth: {
						token: generateToken({ length: 32 })
					}
				}
				controller(Action.AUTH_SUCCESS, user)
				cb()
			} catch (error: any) {
				if (error.response && error.response.status === 404) {
					controller(Action.SET_PENDING_ACCESS, email)
				}
				handleError(error)
			} finally {
				controller(Action.SET_LOADING, false)
			}
		})()
	}

	const logout = () => {
		// clearHttpAuthHeader()
		controller(Action.RESET)
		// dispatch(resetAccount())
		// dispatch(resetNetwork())
		// dispatch(resetForm())
	}

	const reset = () => controller(Action.RESET)
	const clearError = () => controller(Action.CLEAR_ERROR)

	const useAuthState = () => {
		return state
	}

	return (
		<AuthContext.Provider
			value={{
				useAuthState,
				authenticate,
				logout,
				reset,
				clearError
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
