export interface LayoutState {
	loading?: boolean
	styles?: any
	topBarOpen?: boolean
	screen?: string
}

export const initialState: LayoutState = {
	loading: false,
	styles: {},
	topBarOpen: false,
	screen: 'login'
}
