import { useContext, useEffect } from 'react'
import { LayoutContext } from '../layout/LayoutContext'

export interface ScrollTopProps {
	children: any
}

const ScrollTop = ({ children }: ScrollTopProps) => {
	const { useLayoutState }: any = useContext(LayoutContext)
	const state = useLayoutState()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}, [state.screen])

	return children
}

export default ScrollTop
