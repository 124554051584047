import ScrollTop from '../../components/ScrollTop'
import Layout from '../../layout/Layout'
import Router from './Router'

export default function App() {
	return (
		<ScrollTop>
			<>
				<Layout>
					<Router />
				</Layout>
			</>
		</ScrollTop>
	)
}
