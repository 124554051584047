import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/styles'
import './index.scss'
import App from './app/screens/App'
import { AuthProvider } from './auth/AuthContext'
import { LayoutProvider } from './layout/LayoutContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<ThemeProvider
		theme={{
			palette: 'light'
		}}
	>
		<LayoutProvider>
			<AuthProvider>
				<App />
			</AuthProvider>
		</LayoutProvider>
	</ThemeProvider>
)
