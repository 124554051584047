import { LinearProgress } from '@mui/material'
import { ComponentType, LazyExoticComponent, ReactNode, Suspense } from 'react'

export default function Loadable(
	Component: LazyExoticComponent<() => JSX.Element> | ComponentType<ReactNode> | any
) {
	return (props: any) => (
		<Suspense fallback={<LinearProgress />}>
			<Component {...props} />
		</Suspense>
	)
}
