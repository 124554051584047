import { PayloadAction } from '../utils/createAction'
import { LayoutState } from './state'

export enum Action {
	SET_STYLES,
	SET_LOADING,
	SET_TOPBAR_OPEN,
	SET_SCREEN
}

const setStyles = (state: LayoutState, payload: any) => ({
	...state,
	styles: payload
})

const setLoading = (state: LayoutState, payload: boolean) => ({
	...state,
	loading: payload
})

const setTopBarOpen = (state: LayoutState, payload: boolean) => ({
	...state,
	topBarOpen: payload
})

const setScreen = (state: LayoutState, payload: string) => ({
	...state,
	screen: payload
})

const reducer = (state: LayoutState, { type, payload }: PayloadAction<any, Action>) => {
	switch (type) {
		case Action.SET_STYLES:
			return setStyles(state, payload)
		case Action.SET_LOADING:
			return setLoading(state, payload)
		case Action.SET_TOPBAR_OPEN:
			return setTopBarOpen(state, payload)
		case Action.SET_SCREEN:
			return setScreen(state, payload)
		default:
			throw Error('InvalidType')
	}
}
export default reducer
