import { lazy } from 'react'
import Loadable from '../components/Loadable'

const LoginScreen = Loadable(lazy(() => import('../auth/views/Login')))
const DashboardScreen = Loadable(lazy(() => import('../app/screens/Dashboard')))
const TagEditor = Loadable(lazy(() => import('../tag-builder/screens/TagEditor')))

export default {
	login: LoginScreen,
	dashboard: DashboardScreen,
	tagBuilder: TagEditor
} as { [key: string]: any }
