import { PayloadAction } from '../utils/createAction'
import { initialState } from './state'
import { AuthState } from './types'

export enum Action {
	SET_LOADING,
	AUTH_SUCCESS,
	AUTH_ERROR,
	CLEAR_ERROR,
	SET_PENDING_ACCESS,
	CLEAR_PENDING_ACCESS,
	RESET
}

const setLoading = (state: AuthState, payload: boolean) => ({
	...state,
	loading: payload
})

const authorizeSuccess = (state: AuthState, payload: any) => ({
	...state,
	user: payload.user,
	token: payload.auth.token
})

const authError = (state: AuthState, payload: string) => ({
	...state,
	error: payload
})

const clearError = (state: AuthState) => ({
	...state,
	error: ''
})

const setPendingAccess = (state: AuthState, payload: string) => ({
	...state,
	pendingAccess: payload
})

const clearPendingAccess = (state: AuthState) => ({
	...state,
	pendingAccess: ''
})

const reset = () => initialState

const reducer = (state: any, { type, payload }: PayloadAction<any, Action>) => {
	switch (type) {
		case Action.SET_LOADING:
			return setLoading(state, payload)
		case Action.AUTH_SUCCESS:
			return authorizeSuccess(state, payload)
		case Action.AUTH_ERROR:
			return authError(state, payload)
		case Action.CLEAR_ERROR:
			return clearError(state)
		case Action.SET_PENDING_ACCESS:
			return setPendingAccess(state, payload)
		case Action.CLEAR_PENDING_ACCESS:
			return clearPendingAccess(state)
		case Action.RESET:
			return reset()
		default:
			throw Error('InvalidType')
	}
}
export default reducer
