import { useContext, useMemo } from 'react'
import screens from '../../config/screens'
import { LayoutContext } from '../../layout/LayoutContext'

export default function Router() {
	const { useLayoutState }: any = useContext(LayoutContext)
	const state = useLayoutState()

	const Screen = useMemo(() => {
		if (!state.screen) {
			return ''
		}
		return screens[state.screen]
	}, [state.screen])

	if (!Screen) {
		return null
	}

	return <Screen />
}
