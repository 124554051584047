import { createContext, ReactNode, useReducer } from 'react'
import reducer, { Action } from './reducer'
import { initialState } from './state'
import { createAction } from '../utils/createAction'

export const LayoutContext = createContext({
	useLayoutState: () => ({}),
	setStyles: (styles: any) => {},
	setLoading: (loading: boolean) => {},
	setTopBarOpen: (open: boolean) => {},
	setScreen: (screen: string) => {}
})

export interface LayoutProviderProps {
	children: ReactNode
}

export const LayoutProvider = ({ children }: LayoutProviderProps) => {
	const [state, dispatch]: any = useReducer<any>(reducer, initialState)

	const useLayoutState = (): any => {
		return state
	}

	const controller = (type: Action, payload: any) => {
		dispatch(createAction(type, payload))
	}

	const setStyles = (styles: any) => {
		controller(Action.SET_STYLES, styles)
	}

	const setLoading = (styles: any) => {
		controller(Action.SET_LOADING, styles)
	}

	const setTopBarOpen = (styles: any) => {
		controller(Action.SET_TOPBAR_OPEN, styles)
	}

	const setScreen = (screen: string) => {
		console.log('setScreen', screen)
		controller(Action.SET_SCREEN, screen)
	}

	return (
		<LayoutContext.Provider
			value={{
				useLayoutState,
				setStyles,
				setLoading,
				setTopBarOpen,
				setScreen
			}}
		>
			{children}
		</LayoutContext.Provider>
	)
}
